import React from 'react';
import { Button } from 'reactstrap';
import { CategoryGroups, HtmlHelpers, Links } from '@westernmilling/react_shared';

const ItemColumns = (context) => [
  {
    Header: 'Reference',
    accessor: 'reference',
    Cell: (row) => Links.itemViewLink(row.original.uuid, row.original.reference),
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Name',
    accessor: 'name',
    className: 'text-left',
    width: 400,
  },
  {
    Header: 'Short Name',
    accessor: 'short_name',
    className: 'text-left',
  },
  {
    id: 'item_category__name',
    Header: 'Category',
    accessor: 'item_category.name',
    className: 'text-left',
    width: 400,
  },
  {
    id: 'item_category__category_group',
    Header: 'Category Group',
    accessor: 'item_category.category_group',
    Cell: (row) => CategoryGroups[row.original.item_category.category_group],
    className: 'text-center',
  },
  {
    id: 'feed_tag',
    Header: 'Feed Tag',
    Cell: (row) => {
      const id = row.original.id;
      const uuid = row.original.uuid;
      return (
        <div>
          {
            (row.original.feed_tag === null || row.original.feed_tag === '')
              ? ''
              : (
                <Button color="link" onClick={() => context.getPdf(id, uuid)}>
                  Feed Tag
                </Button>
              )
          }
        </div>
      );
    },
    filterable: false,
    sortable: false,
    csvShow: false,
    className: 'text-center',
  },
];

export default ItemColumns;

import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/react_shared';

import Api from 'Api';
import Columns from 'helpers/columns/Items';

export default withAuth(class ItemListContainer extends CrudList {
  getPageTitle = () => 'Active Item List';

  getColumns = () => Columns(this);

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  getListApi = () => 'items';

  getStorageName = () => 'otto_active_items_list';

  indexTable = () => this.paginationTable();

  loadOnMount = () => '';

  getListParams = () => ({
    filtered: [{ id: 'active', value: 'Y' }],
  });

  canDownloadCsv = () => true;

  getPdf = (id, uuid) => (
    Api.feedTagPdfUrl(this.props.auth, uuid, `FeedTag_${id}.pdf`)
  )
});

import React from 'react';
import PropTypes from 'prop-types';

const WmLabel = function WmLabel({ htmlFor, ...props }) {
  return (
    <label id={props.id} className="wm__label" htmlFor={htmlFor}>
      {props.text}
    </label>
  );
};

WmLabel.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string,
  htmlFor: PropTypes.string,
};

WmLabel.defaultProps = {
  htmlFor: '',
  id: '',
};

export default WmLabel;

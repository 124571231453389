function doesExist(rowId) {
  return rowId !== null && rowId !== undefined;
}

let getObjectChildren;

const checkString = (value) => {
  let string = value.toString().toLowerCase();
  if (string === '[object object]') {
    string = getObjectChildren(value);
  }
  return string.toString();
};

getObjectChildren = (value) => {
  let string = '';
  const kids = value.props.children;
  if (Array.isArray(kids)) {
    kids.forEach((k) => {
      const subString = checkString(k);
      string += subString;
    });
  } else {
    string = kids;
  }
  return string;
};

function escapeRegEx(input) {
  let rgxPattern = input;
  const special = [
    '\\', '.', '+', '*', '?', '[', '^', ']', '$', '(',
    ')', '{', '}', '=', '!', '<', '>', '|', ':', '-',
  ];

  special.forEach((char) => {
    rgxPattern = rgxPattern.replace(new RegExp(`\\${char}`, 'g'), `\\${char}`);
  });
  return rgxPattern.replace(/[ ]/g, '.*');
}

function checkValue(filter, value) {
  let string = checkString(value).toLowerCase();
  let filterValue = filter.value.toLowerCase();
  if (filter.id === 'release_number') {
    string = string.replace(/[ -]/g, '');
    filterValue = filterValue.replace(/[ -]/g, '');
  }

  filterValue = escapeRegEx(filterValue);
  return RegExp(filterValue).test(string);
}

function checkArray(rowId, filter) {
  let found = false;
  rowId.some((value) => {
    found = checkValue(filter, value);
    return found === true;
  });
  return found;
}

function checkThis(row, id, filter) {
  let found = false;
  if (Array.isArray(row[id])) {
    found = checkArray(row[id], filter);
  } else {
    found = checkValue(filter, row[id]);
  }
  return found;
}

export const wmDefaultTableFilter = (filter, row) => {
  const id = filter.pivotId || filter.id;
  let found = false;
  if (doesExist(row[id])) {
    found = checkThis(row, id, filter);
  } else {
    found = false;
  }
  return found;
};

export default { Default: wmDefaultTableFilter };

import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

const AlertErrors = function AlertErrors(props) {
  return props.errors.length > 0
    ? (
      <div>
        { props.errors.map((error, idx) => (
          <Alert key={`alert_${idx + 1}`} color="danger">{error}</Alert>
        ))}
      </div>
    )
    : <div />;
};

AlertErrors.propTypes = {
  errors: PropTypes.array.isRequired,
};

export default AlertErrors;

import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';

import Api from 'Api';
import Columns from 'helpers/columns/Preloads';
import WmReactTable from 'components/Layout/WmReactTable';
import WmDropdown from 'components/Layout/WmDropdown';
import WmDateField from 'components/Layout/WmDateField';
import { UserGroups } from '@westernmilling/react_shared';

import Page from 'components/Layout/Page';
import Help from 'helpers/HtmlHelpers';

const moment = require('moment');

export default withAuth(class OrderPreloadListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      scalehouses: [],
      scalehouse: null,
      selectedDate: moment(),
    };
  }

  async componentDidMount() {
    const userInfo = await UserGroups.UserInfo(this.props.auth);
    this.props.setUserInfo(userInfo);
    Api.scalehouseList(this.props.auth)
      .then((response) => {
        const scalehouses = Help.buildOptions(response, 'name', 'uuid');
        this.setState({ scalehouses });
      });
  }

  getOrders = (scalehouse) => {
    const dt = this.state.selectedDate.format('YYYY-M-D');
    const scale = scalehouse.value;
    Api.orderOutBoundPreloadList(this.props.auth, scale, dt)
      .then((response) => {
        if (response.data !== undefined) {
          this.setState({
            data: response.data.map((d) => d.attributes),
            loading: false,
          });
        } else {
          this.setState({ apiError: true });
        }
      });
  }

  getData = () => {
    let data = <div className="text-center">Please select a Scalehouse and date.</div>;
    if (!Help.is_blank(this.state.scalehouse) && !Help.is_blank(this.state.selectedDate)) {
      data = (
        <div>
          <WmReactTable
            data={this.state.data}
            loading={this.state.loading}
            columns={Columns(this)}
          />
        </div>
      );
    }
    return data;
  }

  getDropdown = () => (
    <WmDropdown
      text="Select Scalehouse"
      name="scalehouse"
      htmlFor="scalehouse"
      labelColumns={3}
      fieldColumns={3}
      values={this.state.scalehouse}
      options={this.state.scalehouses}
      onChange={this.chooseScalehouse}
      isRequired
    />
  );

  getDate = () => (
    <WmDateField
      text="Delivery Date"
      name="selectedDate"
      htmlFor="selectedDate"
      labelColumns={3}
      fieldColumns={3}
      value={this.state.selectedDate}
      onChange={this.chooseSelectedDate}
      isRequired
    />
  );

  getContents = () => (
    <div>
      { this.getDropdown() }
      { this.getDate() }
      <hr />
      <br />
      {this.getData()}
    </div>
  )

  getPdf = (id, uuid) => {
    Api.orderOutBoundPreloadPdf(this.props.auth, uuid, `PreloadTicket_${id}.pdf`);
  }

  getAllPdf = () => {
    const dt = this.state.selectedDate.format('YYYY-M-D');
    const scale = this.state.scalehouse.value;
    Api.orderOutBoundAllPreloadPdf(this.props.auth, scale, dt, `PreloadTicket_${dt}.pdf`);
  }

  chooseScalehouse = (event) => {
    this.setState({ scalehouse: event.target.value, loading: true });
    this.getOrders(event.target.value);
  }

  chooseSelectedDate = (event) => {
    this.setState({
      selectedDate: event.target.value,
      loading: true,
    }, () => {
      if (!Help.is_blank(this.state.scalehouse)) {
        this.getOrders(this.state.scalehouse);
      }
    });
  }

  render() {
    const contents = this.state.apiError ? Api.apiError() : this.getContents();
    return (
      <Page
        title="Outbound Preload List"
        contents={contents}
      />
    );
  }
});

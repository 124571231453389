import { withAuth } from '@okta/okta-react';
import { CrudView } from '@westernmilling/react_shared';

import ItemForm from 'components/Forms/OttoUpdateView';

export default withAuth((class OttoUpdateViewContainer extends CrudView {
  constructor(props) {
    super(props);
    this.state.section = this.getSection();
  }

  getSection = () => (
    {
      type: 'otto_updates',
      form: ItemForm,
      title: '',
      listRoute: '../../',
      listLabel: 'Otto Updates List',
      edit_admins: [],
    }
  )
}));

import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = function PageTitle(props) {
  return (
    <div className="align-self-center">
      <h2 id="wm_page_title">{props.title}</h2>
      <br />
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitle;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Row } from 'reactstrap';
import { SingleDatePicker } from 'react-dates';

import HtmlHelpers from 'helpers/HtmlHelpers';

export default class WmDateField extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      focused: false,
    };
    this.state = this.initialState;
    this.dateRef = React.createRef();
  }

  getDateField = () => (
    <Col md={this.props.fieldColumns}>
      <SingleDatePicker
        ref={this.dateRef}
        id={this.props.name}
        date={this.props.value}
        onDateChange={this.dateChange}
        required={this.props.isRequired}
        focused={this.state.focused}
        onFocusChange={({ focused }) => this.setState({ focused })}
        small
        isOutsideRange={(day) => this.checkDate(day)}
        firstDayOfWeek={1}
      />
      <div className="invalid-feedback" />
    </Col>
  );

  checkDate = (day) => (
    this.props.checkDate !== undefined ? this.props.checkDate(day) : false
  )

  dateChange = (selected) => {
    const value = {
      target: {
        name: this.props.name,
        value: selected,
      },
    };
    this.props.onChange(value);
  }

  render() {
    return (
      <FormGroup>
        <Row>
          { HtmlHelpers.getWmLabel(this.props) }
          { this.getDateField() }
        </Row>
      </FormGroup>
    );
  }
}

WmDateField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  fieldColumns: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};

WmDateField.defaultProps = {
  isRequired: true,
  value: null,
};

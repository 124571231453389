import React from 'react';
import { Button } from 'reactstrap';

import Help from 'helpers/HtmlHelpers';

function source(type, values) {
  const farm = Help.is_blank(values.farm) ? '' : (
    <div>
      `Farm: $
      {values.farm}
      `
    </div>
  );
  const field = Help.is_blank(values.field) ? '' : (
    <div>
      `field: $
      {values.farm}
      `
    </div>
  );

  return (
    <div>
      {values.source_location_name}
      {farm}
      {field}
    </div>
  );
}

function sourceString(type, values) {
  return (values.source_location_name + values.farm + values.field);
}

const OpenOutboundScaleTicketsColumns = (context) => [
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
    minWidth: 250,
  },
  {
    Header: 'Destination',
    accessor: 'destination_location_name',
    className: 'text-left',
    minWidth: 150,
  },
  {
    id: 'source',
    Header: 'Source',
    accessor: (d) => sourceString('tare', d).toString(),
    Cell: (row) => source('tare', row.original),
    className: 'text-left',
    minWidth: 150,
  },
  {
    Header: 'Carrier',
    accessor: 'carrier_name',
    className: 'text-left',
    minWidth: 150,
  },
  {
    Header: 'Driver',
    accessor: 'driver_name',
    className: 'text-left',
    minWidth: 150,
  },
  {
    Header: 'Order #',
    accessor: 'order_number',
    className: 'text-left',
    minWidth: 100,
  },
  {
    Header: 'PO #',
    accessor: 'po_number',
    className: 'text-left',
    minWidth: 100,
  },
  {
    Header: () => (
      <Button disabled={context.state.disabled} color="info" onClick={() => context.getAllPdf()}>
        <span className="small">All Preload Tickets</span>
      </Button>
    ),
    Cell: (row) => {
      const id = row.original.id;
      const uuid = row.original.uuid;
      return (
        <Button className="wm__no_padding" color="link" onClick={() => context.getPdf(id, uuid)}>
          <span className="small">Preload Ticket</span>
        </Button>
      );
    },
    className: 'text-center',
    minWidth: 100,
    sortable: false,
    filterable: false,
  },
];

export default OpenOutboundScaleTicketsColumns;

import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import PageTitle from 'components/Layout/PageTitle';
import Help from 'helpers/HtmlHelpers';

const pageStyle = { padding: '0px 20px 0px 20px' };

export default withAuth(class Page extends Component {
  getUser = () => {
    let name = '';
    const token = JSON.parse(localStorage.getItem('okta-token-storage'));
    if (!Help.is_blank(token)) {
      name = Help.dig([token, 'idToken', 'claims', 'name']) || '';
      name = name.split(' ')[0];
    }
    return name;
  }

  render() {
    return (
      <div className="App">
        <div style={pageStyle}>
          <PageTitle title={this.props.title} />
          {this.props.contents}
        </div>
      </div>
    );
  }
});

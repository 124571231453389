import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Col, FormGroup, Input, Row,
} from 'reactstrap';
import { CrudForm, HtmlHelpers } from '@westernmilling/react_shared';

export default withAuth(withAlert()(class OttoUpdate extends CrudForm {
  formType = () => 'Change Log Entry';

  getCrudType = () => 'otto_updates';

  getModalClassName = () => 'wm__modal-l';

  handleHtmlBodyChange = (value) => {
    const newData = { ...this.state.item };
    newData.html_body = value;
    this.setState({ item: newData });
  }

  getLabelColumns = () => 2;

  getTextareaRow = (fieldName, label, required = false, value = null) => {
    const uploadLabel = { text: label, htmlFor: fieldName, labelColumns: this.getLabelColumns() };
    return this.inRow(
      <FormGroup>
        <Row className="align-items-center">
          { HtmlHelpers.getWmLabel(uploadLabel) }
          <Col md="9">
            <Input
              type="textarea"
              className="form-control"
              name={fieldName}
              value={value || this.state.item[fieldName] || ''}
              onChange={this.onChange}
              required={required}
              rows={20}
            />
            <div className="invalid-feedback" />
          </Col>
        </Row>
      </FormGroup>,
    );
  }

  modalBodyRows = () => (
    <div>
      {this.getDateFieldRow('release_date', 'Release Date')}
      { this.getTextFieldRow('title', 'Title') }
      { this.getTextFieldRow('subtitle', 'Subtitle', false) }
      { this.yesNoField('is_visible', 'Visible') }
      { this.getTextareaRow('html_body', 'HTML body') }
    </div>
  )

  successAlertMsg = () => this.successMsg(this.formType());

  successMsg = (type) => (
    [
      `${type} ${this.isNew() ? 'created' : 'updated'} successfully`,
      'success',
    ]
  );
}));

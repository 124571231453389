import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';

import Api from 'Api';
import Columns from 'helpers/columns/Orders';
import WmReactTable from 'components/Layout/WmReactTable';
import { UserGroups } from '@westernmilling/react_shared';

import Page from 'components/Layout/Page';

export default withAuth(class OrderClosedListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
  }

  async componentDidMount() {
    const userInfo = await UserGroups.UserInfo(this.props.auth);
    this.props.setUserInfo(userInfo);

    Api.orderClosedList(this.props.auth)
      .then((response) => {
        if (response.data !== undefined) {
          this.setState({
            data: response.data.map((d) => d.attributes),
            loading: false,
          });
        } else {
          this.setState({ apiError: true });
        }
      });
  }

  getContents = () => (
    <div>
      Orders on this list have a status of shipped or invoiced
      and a delivery date within the last 2 weeks
      <WmReactTable
        data={this.state.data}
        loading={this.state.loading}
        columns={Columns(this)}
      />
    </div>
  )

  render() {
    const contents = this.state.apiError ? Api.apiError() : this.getContents();
    return (
      <Page
        title="Closed Order List (last 14 days)"
        contents={contents}
      />
    );
  }
});

// App info

import { AppJs } from '@westernmilling/react_shared';

import InfoListContainer from 'containers/InfoListContainer';
import ItemListContainer from 'containers/ItemListContainer';
import OrderClosedListContainer from 'containers/OrderClosedListContainer';
import OrderListContainer from 'containers/OrderListContainer';
import OrderPreloadListContainer from 'containers/OrderPreloadListContainer';
import OttoUpdateListContainer from 'containers/OttoUpdateListContainer';
import OttoUpdateViewContainer from 'containers/OttoUpdateViewContainer';

export default class App extends AppJs {
  defaultRoute = () => InfoListContainer;

  routes = () => [
    { path: '/closed_orders', component: OrderClosedListContainer },
    { path: '/orders', component: OrderListContainer },
    { path: '/items', component: ItemListContainer },
    { path: '/outbound_no_scale', component: OrderPreloadListContainer },
    { path: '/otto_updates', component: OttoUpdateListContainer },
    { path: '/otto_updates/view/:uuid', component: OttoUpdateViewContainer },
  ];
}

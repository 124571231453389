import React from 'react';
import { Links } from '@westernmilling/react_shared';

import Help from 'helpers/HtmlHelpers';

const Moment = require('moment');

const OrderColumns = () => [
  {
    Header: 'Order ID',
    accessor: 'order_number',
    Cell: (row) => Links.orderViewLink(row.original.uuid, row.original.order_number),
    className: 'text-center',
    width: 125,
  },
  {
    Header: 'Delivery Date',
    accessor: 'delivery_date',
    className: 'text-center',
    sortMethod: Help.dateSort,
  },
  {
    Header: 'Time Slot',
    accessor: 'time_slot',
    className: 'text-left',
  },
  {
    Header: 'Mixer Slot',
    accessor: 'requested_time_slot_time',
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    Header: 'Item Cat Grp',
    accessor: 'item_group',
    className: 'text-left',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    className: 'text-right',
  },
  {
    Header: 'Ship To',
    accessor: 'destination_name',
    className: 'text-left',
  },
  {
    Header: 'Pick Up',
    accessor: 'source_info',
    className: 'text-left',
  },
  {
    Header: 'Freight Terms',
    accessor: 'freight_terms',
    className: 'text-left',
    filterMethod: (filter, row) => {
      let match;
      switch (filter.value) {
        case 'DEL': match = row[filter.id] === 'Delivery included'; break;
        case 'FOB': match = row[filter.id] === 'Delivery not included'; break;
        case 'DBV': match = row[filter.id] === 'Delivered by Vendor'; break;
        case 'All':
        default: match = true;
      }
      return match;
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={(event) => onChange(event.target.value)}
        style={{ width: '100%' }}
        filter={filter}
      >
        <option value="All">Show All</option>
        <option value="DEL">DEL - Delivery Included</option>
        <option value="FOB">FOB - Delivery not Included</option>
        <option value="DBV">Delivered by Vendor</option>
      </select>
    ),
    width: 150,
  },
  {
    Header: 'Carrier',
    accessor: 'carrier_name',
    className: 'text-left',
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-left',
  },
  {
    Header: 'Salesperson',
    accessor: 'salesperson_name',
    className: 'text-left',
  },
  {
    Header: 'Ordered by',
    accessor: 'ordered_by',
    className: 'text-left',
  },
  {
    Header: 'Created by',
    accessor: 'created_by',
    className: 'text-left',
  },
  {
    id: 'created_at',
    Header: 'Created at',
    accessor: (d) => (new Moment(new Date((d.created_at_utc)))).format('M/D/YYYY h:mm:ss a'),
    className: 'text-left',
  },
];

export default OrderColumns;

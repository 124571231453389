import React from 'react';
import { Button } from 'reactstrap';
import { HtmlHelpers } from '@westernmilling/react_shared';

import ColumnButton from 'helpers/columns/ColumnButton';

const baseLink = `${process.env.REACT_APP_BASE_URL}/info${process.env.REACT_APP_URL_ENV}`;
const getLink = (value, label) => (<a href={`${baseLink}/otto_updates/view/${value}`}>{label}</a>);

const Columns = (context) => [
  {
    Header: 'Release Date',
    accessor: 'release_date',
    sortMethod: HtmlHelpers.dateSort,
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Change',
    accessor: 'title',
    Cell: (row) => {
      const label = <h5>{row.original.title}</h5>;
      return (
        <div>
          {getLink(row.original.uuid, label)}
          <br />
          <span className="text-muted">
            {row.original.subtitle}
          </span>
        </div>
      );
    },
    className: 'text-left',
  },
  {
    Header: 'Visible',
    accessor: 'is_visible_text',
    className: 'text-center',
    width: 150,
    show: context.isEditAdmin(),
  },
  {
    id: 'log',
    Cell: (row) => (
      <Button className="wm__no_padding" color="link" onClick={() => context.openAuditLogModal(row.original.uuid)}>
        Audit Log
      </Button>
    ),
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 150,
    show: context.isEditAdmin(),
    csvShow: false,
  },
  {
    id: 'selection',
    Cell: (row) => (
      <div>
        <ColumnButton
          onClick={() => context.selectItem(row.index)}
          text="edit"
        />
      </div>
    ),
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 100,
    show: context.isEditAdmin(),
    csvShow: false,
  },
];

export default Columns;

import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';

import TableFilters from 'helpers/TableFilters';

export default class WmReactTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtered: props.filtered,
    };
  }

  render() {
    return (
      <ReactTable
        data={this.props.data}
        loading={this.props.loading}
        showPagination
        columns={this.props.columns}
        className="-striped -highlight"
        minRows={3}
        filterable
        filtered={this.props.filtered || this.state.filtered}
        onFilteredChange={(filtered) => {
          if (this.props.onFilteredChange !== undefined) {
            this.props.onFilteredChange(filtered);
          } else {
            this.setState({ filtered });
          }
        }}
        defaultFilterMethod={TableFilters.Default}
        defaultSorted={this.props.sortBy}
      />
    );
  }
}

WmReactTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  sortBy: PropTypes.array,
};

WmReactTable.defaultProps = {
  sortBy: [],
};

import React from 'react';
import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/react_shared';

import Columns from 'helpers/columns/OttoUpdates';
import ItemForm from 'components/Forms/OttoUpdate';
import ViewForm from 'components/Forms/OttoUpdateView';

export default withAuth(class OttoUpdateListContainer extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'otto_updates';

  getPageTitle = () => 'Otto Updates';

  getAddText = () => 'Create Change Log Entry';

  getAddGroups = () => ['otto_admin'];

  getEditGroups = () => ['otto_admin'];

  getStorageName = () => 'otto_inf_otto_updates_list';

  canDownloadCsv = () => true;

  auditLogModelType = () => 'OttoUpdate';

  getWidthCenter = () => 8;

  getWidthLeft = () => 2;

  getWidthRight = () => 2;

  openViewLogModal = (id) => this.setState({ viewLogModalOpen: true, viewItem: id })

  closeViewLogModal = () => this.setState({ viewLogModalOpen: false, viewItem: null })

  bulkForm = () => (
    this.state.viewLogModalOpen ? (
      <ViewForm
        userGroups={this.state.userGroups}
        data={this.state.data}
        editItem={this.state.viewItem}
        showModal={this.state.viewLogModalOpen}
        closeModal={this.closeViewLogModal}
      />
    ) : ''
  )
});

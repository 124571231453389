import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { Col, Row } from 'reactstrap';
import { UserGroups } from '@westernmilling/react_shared';

import Page from 'components/Layout/Page';
import OttoUpdates from 'containers/OttoUpdateListContainer';

export default withAuth(class InfoListContainer extends Component {
  async componentDidMount() {
    const userInfo = await UserGroups.UserInfo(this.props.auth);
    this.props.setUserInfo(userInfo);
  }

  getContents = () => (
    <div>
      <Row>
        <Col md="4">{this.firstColumn()}</Col>
        <Col md="4">{this.secondColumn()}</Col>
        <Col md="4">{this.thirdColumn()}</Col>
      </Row>
    </div>
  )

  firstColumn = () => (
    <div className="text-center">
      <h2>Items</h2>
      <a href={`/${process.env.REACT_APP_BASENAME}/items`}>Active Items</a>
      <br />
    </div>
  )

  secondColumn = () => (
    <div className="text-center">
      <h2>Orders</h2>
      <a href={`/${process.env.REACT_APP_BASENAME}/orders`}>Open Orders</a>
      <br />
      <a href={`/${process.env.REACT_APP_BASENAME}/closed_orders`}>Closed Orders (last 14 days)</a>
      <br />
      <a href={`/${process.env.REACT_APP_BASENAME}/outbound_no_scale`}>Outbound by Scale (no weights)</a>
      <br />
    </div>
  )

  thirdColumn = () => (
    <div className="text-center">
      <h2>Scale Tickets</h2>
      <a href={`${process.env.REACT_APP_SCALE_TICKETS_URL}/scale_tickets`}>Scale Ticket List</a>
      <br />
      <a href={`${process.env.REACT_APP_SCALE_TICKETS_URL}/open_outbounds`}>Open Outbound Scale Tickets</a>
      <br />
      <a href={`${process.env.REACT_APP_SCALE_TICKETS_URL}/edit_list`}>Edited Scale Tickets</a>
    </div>
  )

  render() {
    return (
      <div>
        <Page title="" contents={this.getContents()} />
        <br />
        <br />
        <br />
        <hr />
        <OttoUpdates />
      </div>
    );
  }
});

import React from 'react';
import 'whatwg-fetch';

import Help from 'helpers/HtmlHelpers';
import AlertErrors from 'components/Layout/AlertErrors';

const queryString = require('query-string');

const Api = {};

const contractoApiHost = process.env.REACT_APP_CONTRACTO_API_URL;

Api.feedTagPdfUrl = (authToken, uuid, filename) => (
  Api.doPdfRequest(
    'GET',
    `${contractoApiHost}/v1/feed_tags/${uuid}`,
    filename,
    authToken,
  )
);

Api.scaleTicketPdf = (authToken, uuid, filename) => (
  Api.doPdfRequest(
    'GET',
    `${contractoApiHost}/v1/scale_tickets/${uuid}.pdf`,
    filename,
    authToken,
  )
);

Api.orderOutBoundPreloadPdf = (authToken, uuid, filename) => (
  Api.doPdfRequest(
    'GET',
    `${contractoApiHost}/v1/preload_pickup_tickets/${uuid}.pdf`,
    filename,
    authToken,
  )
);

Api.orderOutBoundAllPreloadPdf = (authToken, scale, dateRequested, filename) => (
  Api.doPdfRequest(
    'GET',
    `${contractoApiHost}/v1/preload_pickup_tickets.pdf?id=${scale}&date_requested=${dateRequested}`,
    filename,
    authToken,
  )
);

Api.orderList = (authToken) => Api.doGet(
  authToken,
  `${contractoApiHost}/v1/orders?info=Y`,
);

Api.orderClosedList = (authToken) => Api.doGet(
  authToken,
  `${contractoApiHost}/v1/orders?closed_info=Y`,
);

Api.orderOutBoundPreloadList = (authToken, uuid, dateSelected) => Api.doGet(
  authToken,
  `${contractoApiHost}/v1/orders?order_type=outbound&scalehouse=${uuid}&dt=${dateSelected}`,
);

Api.itemList = (authToken) => Api.doGet(
  authToken,
  `${contractoApiHost}/v1/items?info=Y`,
);

/* eslint-disable no-console */
Api.scaleTicketListPagination = (authToken, page, pageSize, sorted, filtered) => {
  const params = queryString.stringify({
    page,
    pageSize,
    sorted: JSON.stringify(sorted),
    filtered: JSON.stringify(filtered),
  });
  const url = `${contractoApiHost}/v1/scale_tickets?${params}`;

  return Api.doGet(authToken, url).then((response) => response).catch((response) => console.log(response));
};
/* eslint-enable no-console */

Api.scalehouseList = (authToken) => Api.doGet(
  authToken,
  `${contractoApiHost}/v1/scalehouses?order=name`,
);

Api.openOutboundScaleTicketList = (authToken) => Api.doGet(
  authToken,
  `${contractoApiHost}/v1/scale_tickets?open=Y&type=outbound`,
);

Api.doGet = (authToken, url) => Api.doRequest('GET', url, null, authToken);

Api.doPut = (authToken, url, body = null) => Api.doRequest('PUT', url, body, authToken);

Api.doPost = (authToken, url, body = null) => Api.doRequest('POST', url, body, authToken);

Api.doRequest = async (type, url, body, auth) => {
  const authToken = await Api.getAuthToken(auth);
  return fetch(url, {
    method: type,
    body,
    headers: { Authorization: authToken },
  }).then((response) => response.json());
};

Api.doPdfRequest = async (type, url, filename, auth) => {
  const authToken = await Api.getAuthToken(auth);
  return fetch(url, {
    method: type,
    headers: { Authorization: authToken },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
};

Api.getAuthToken = async (auth) => {
  const info = JSON.parse(localStorage.getItem('okta-token-storage'));
  const client = Help.dig([info, 'idToken', 'clientId']);
  const desiredClient = process.env.REACT_APP_OKTA_CLIENT_ID;

  if (client !== desiredClient) {
    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('okta-cache-storage');
    Api.reloadExpiredPage(false);
  } else if (!await auth.isAuthenticated()) {
    Api.reloadExpiredPage(true);
  }
  return auth.getIdToken();
};

Api.reloadExpiredPage = (showMessage) => {
  let url = window.location.href;
  if (showMessage) {
    url += url.indexOf('?') > -1 ? '&' : '?';
    url += 'expired=Y';
  }
  window.location.href = url;
};

Api.apiError = () => (
  <AlertErrors errors={['There has been a problem loading the data, please refresh this page.']} />
);

export default Api;

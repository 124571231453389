import React from 'react';
import * as moment from 'moment';
import { Col } from 'reactstrap';

import WmLabel from 'components/Layout/WmLabel';

const HtmlHelpers = {};

HtmlHelpers.getWmToken = async (auth) => {
  if (!await auth.isAuthenticated()) {
    window.location.reload();
  }
  return auth.getIdToken();
};

HtmlHelpers.dig = (val) => {
  const values = Array.isArray(val) ? val : [val];
  let obj = values[0];
  for (let i = 0; i < values.length; i += 1) {
    if (i !== 0) {
      obj = obj[values[i]];
    }

    if (obj === undefined || obj === null) {
      return null;
    }
  }
  return obj;
};

HtmlHelpers.is_not_null = (val) => (
  val !== undefined && val !== null
);

HtmlHelpers.is_blank = (val) => (
  val === undefined || val === null || val === ''
);

HtmlHelpers.format_date = (date) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return date.toLocaleDateString('en-US', options).replace(/\//g, '-');
};

HtmlHelpers.findAncestor = (el, cls) => {
  let temp = el;
  while (((temp = temp.parentElement)) && !temp.classList.contains(cls));
  return temp;
};

HtmlHelpers.validateForm = (formEl) => {
  HtmlHelpers.setEachField(formEl);
  return formEl.checkValidity() !== false;
};

HtmlHelpers.setEachField = (formEl) => {
  const formLength = formEl.length;
  for (let i = 0; i < formLength; i += 1) {
    const elem = formEl[i];
    HtmlHelpers.setError(elem);
  }
};

HtmlHelpers.convertRailsErrors = (errors) => {
  const errMsgs = [];
  errors.forEach((error) => {
    const keys = Object.keys(error);
    keys.forEach((key) => {
      let msgbase = '';
      if (key !== 'base') {
        msgbase = HtmlHelpers.toCapitalizedWords(key);
        msgbase += ' ';
      }
      if (Array.isArray(error[key])) {
        error[key].forEach((msg) => {
          errMsgs.push(msgbase + msg);
        });
      } else {
        errMsgs.push(msgbase + error[key]);
      }
    });
  });
  return errMsgs;
};

HtmlHelpers.toCapitalizedWords = (name) => {
  const words = name.match(/[A-Za-z][^_\-A-Z]*/g) || [];
  return words.map(HtmlHelpers.capitalize).join(' ');
};

HtmlHelpers.capitalize = (word) => (
  word.charAt(0).toUpperCase() + word.substring(1)
);

HtmlHelpers.fieldHasFeedback = (errorLabel, elem) => errorLabel && elem.nodeName.toLowerCase() !== 'button';

HtmlHelpers.setError = (elem) => {
  const parent = HtmlHelpers.findAncestor(elem, 'form-group');
  const errorLabel = parent ? parent.querySelector('.invalid-feedback') : null;

  if (HtmlHelpers.fieldHasFeedback(errorLabel, elem)) {
    if (!elem.validity.valid) {
      let msg = elem.validationMessage;
      if (msg === 'Please match the requested format.') {
        msg = elem.title;
      }
      errorLabel.textContent = msg;
      errorLabel.style.display = 'block';
    } else {
      errorLabel.textContent = '';
      errorLabel.style.display = 'none';
    }
  }
};

HtmlHelpers.freightTermOptions = [
  { label: 'Delivery Included', value: 'delivery_included' },
  { label: 'Delivery not Included', value: 'delivery_not_included' },
  { label: 'Delivered by Vendor', value: 'delivered_by_vendor' },
];

HtmlHelpers.buildMonthOptions = (future, prior = 0) => {
  const totalMonths = 1 + prior + future;
  const options = [];
  const dt = moment().startOf('month').subtract(prior, 'months');

  for (let i = 0; i < totalMonths; i += 1) {
    const optionText = dt.format('MMMM YYYY');
    const optionValue = dt.format('L');
    options.push({ label: optionText, value: optionValue });
    dt.add(1, 'months');
  }
  return options;
};

HtmlHelpers.getWmLabel = (props) => (props.labelColumns > 0
  ? (
    <Col md={props.labelColumns} className="text-right">
      <WmLabel htmlFor={props.htmlfor} text={props.text} />
    </Col>
  )
  : '');

HtmlHelpers.getIncludedData = (included) => {
  const includes = {
    contracts: {},
    pickup_locations: {},
    contract_schedules: {},
    locations: {},
  };
  if (included !== undefined) {
    included.forEach((obj) => {
      switch (obj.type) {
        case 'contracts':
          includes.contracts[obj.id] = obj.attributes; break;
        case 'pickup-locations':
          includes.pickup_locations[obj.id] = obj.attributes; break;
        case 'contract-schedules':
          includes.contract_schedules[obj.id] = obj.attributes; break;
        case 'locations':
          includes.locations[obj.id] = obj.attributes; break;
        default: break;
      }
    });
  }
  return includes;
};

HtmlHelpers.dateSort = (a, b) => {
  if (new Date(a) > new Date(b)) {
    return 1;
  }
  if (new Date(a) < new Date(b)) {
    return -1;
  }
  return 0;
};

HtmlHelpers.buildOptions = (response, labelField, valueField) => {
  let options = [];
  if (response.data !== undefined) {
    options = response.data.map((d) => (
      {
        label: d.attributes[labelField],
        value: d.attributes[valueField],
      }
    ));
  }
  return options;
};

export default HtmlHelpers;
